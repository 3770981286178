@import "theme/ui-components/_variables";

$logoWidth: 143px;
$logoHeight: 53px;
$logoWidthLarge: 178.75px;
$logoHeightLarge: 66.25px;

.logo {
  display: flex;
  align-items: center;
}

.logoMain {
  width: $logoWidth;
  height: $logoHeight;

  @media (min-width: breakpoint("mini")) {
    width: $logoWidthLarge;
    height: $logoHeightLarge;
  }
}
